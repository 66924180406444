import type { TypedDocumentNode } from "@graphql-typed-document-node/core"
import { createContext, useState, type ReactNode } from "react"

/**
 * TODO [api-decoupling]
 *
 * ForceStateContext is used EXCLUSIVELY as a Storybook decorator and should never
 * be used in production code.
 * Its purpose is to force a state of a mocked GraphQL query so we can easily switch the state
 * of a component with just changing the forceState. This can be done via special stories:
 *
 * - LOADING_STORY - sets the forceState to 'loading' - show component in its loading state,
 * - ERROR_STORY - sets the forceState to 'error' - show component in its error state.
 *
 * This will work only for components coupled with API via useGraphqlQuery. As we want to move
 * away from this pattern, this context should be deprecated in the future.
 */

type QueryMap = WeakMap<TypedDocumentNode<any, any>, any>

export const mockMap: QueryMap = new WeakMap()
export const MockInputMapContext = createContext<QueryMap | undefined>(
  undefined
)

export type ForceState = "loading" | "live" | "mock" | "error"

type ForceStateContextType = {
  forceState: ForceState
  setForceState: (state: ForceState) => void
}

export const ForceStateContext = createContext({
  forceState: "live",
} as ForceStateContextType)

export const ForceStateProvider = ({
  children,
  initialForceState = "live",
}: {
  children: ReactNode
  initialForceState?: ForceState
}) => {
  const [forceState, setForceState] = useState<ForceState>(initialForceState)
  const value = { forceState, setForceState }
  return (
    <ForceStateContext.Provider value={value}>
      {children}
    </ForceStateContext.Provider>
  )
}
