import closeIconWhite from "assets/icons/closeIconWhite.svg"
import { Loader, ErrorFallback } from "materia"
import type { ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { modifiers } from "ui-tools"
import s from "./ProductViewModal.module.scss"

// The component provides our visual "view", that the user will view a product within
// It supplies a common loading and error handling for the products for consistency
export const ProductViewModal = ({
  title,
  isLoading,
  onClose,
  error,
  isFramed,
  view,
  children,
}: {
  title: string | null
  isLoading?: boolean | undefined
  onClose: () => void
  error?: Error | undefined
  isFramed?: boolean
  view: "fullScreen" | "portrait" | "landscape"
  children: ReactNode
}) => {
  return (
    <div className={modifiers(s, "viewerWrapper", view, { isFramed })}>
      <div className={s.viewerWrapper__header}>
        {!isFramed && <div>{title}</div>}
        <button
          className={s.viewerWrapper__closeButton}
          onClick={onClose}
          aria-label="Close"
        >
          <img
            className={s.viewerWrapper__buttonIcon}
            src={closeIconWhite}
            alt=""
          />
        </button>
      </div>
      <div className={s.viewerWrapper__content}>
        <Content isLoading={isLoading} error={error}>
          {children}
        </Content>
      </div>
    </div>
  )
}

const Content = ({
  isLoading,
  error,
  children,
}: {
  isLoading?: boolean | undefined
  error?: Error | undefined
  children: ReactNode
}) => {
  const { t } = useTranslation()

  if (isLoading) {
    return <Loader />
  } else if (error) {
    return (
      <ErrorFallback
        error={error}
        title={t("error.GENERIC")}
        message={t("error.VIEWER_ERROR")}
      />
    )
  } else {
    return children
  }
}
