import productImage from "assets/demo/productImage.svg"
import { mockData, registerMockData, type ResultOf } from "graphql-mock"
import { graphql } from "../../graphql"

export type Data = ResultOf<typeof query>

export const query = graphql(`
  query ProductMiniModal($productId: ID!, $platform: Platform) {
    product(id: $productId) {
      id
      title
      image
      progress {
        starsCount
        starsMax
      }

      # Only for sending to skills
      useTransferToken
      productType
      companyId
      title
      descriptionLong
      image
      openExternalBrowser
      showNavigationBar
      playLinks(platform: $platform) {
        appLink
        launcherLink
        webglLink
        appStoreLink
        streamLink
      }
    }
  }
`)

export const mockProductMiniModalData = mockData<Data>({
  product: {
    id: "1",
    title: "Salestraining",
    image: productImage,
    progress: {
      starsCount: 5,
      starsMax: 5,
    },

    companyId: "1",
    descriptionLong: null,
    openExternalBrowser: false,
    productType: "pdf",
    showNavigationBar: false,
    useTransferToken: false,
    playLinks: {
      appLink: null,
      launcherLink: null,
      webglLink: null,
      appStoreLink: null,
      streamLink: null,
    },
  },
})

registerMockData(query, mockProductMiniModalData())
