import { useMatches } from "@tanstack/react-location"
import { useEffect } from "react"

export const DEFAULT_TITLE = "Attensi Portal"

/**
 * Handles changing the document's title based on current route.
 * It can be called in any component to customize the page title.
 * If not explicitly called, it will default to "Attensi Portal".
 */
export const usePageTitle = (title: string | null | undefined) => {
  const matches = useMatches()

  useEffect(() => {
    if (title) {
      document.title = `${title} • ${DEFAULT_TITLE}`
    } else {
      document.title = DEFAULT_TITLE
    }

    return () => {
      document.title = DEFAULT_TITLE
    }
  }, [title, matches])
}
