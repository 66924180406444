import { useQueryParam, useToggle } from "hooks"
import { useTranslation } from "react-i18next"
import { addPlayLinkParams } from "../../../tools/addPlayLinkParams"
import { getAttensiProductPlatformForOS } from "../../../tools/getAttensiProductPlatformForOS"
import { IS_APP } from "../../../vuplex/constants"
import { LauncherOverlay, NotSupported } from "../components"
import { HowToPlay } from "../ProductModalHelp"
import { ProductModalMain } from "../ProductModalMain"
import type { Data } from "./gql"

// Real Talk product can be displayed either in the Launcher, similarly
// to other app based products, or in a browser - but it uses a stream link
// instead of WebGL like other browser based products. This makes it a bit
// different so it has its own set of buttons.
//
// Real Talk is currently not supported on mobile devices.

// TODO [api-decoupling] Replace with type not tied to Data
type Props = {
  playLinks: Data["playLinks"]
  useTransferToken: Data["useTransferToken"]
  supportedPlatforms: Data["supportedPlatforms"]
}
export const RealTalkProductButtons = ({
  playLinks,
  useTransferToken,
  supportedPlatforms,
}: Props) => {
  const [isLauncherOverlayVisible, toggleLauncherOverlay] = useToggle()

  const { launcherLink, streamLink } = playLinks
  // [TODO] REMOVE WHEN THE REAL TALK STREAM IS READY
  const { isStreamLinkEnabled } = useStreamLink(streamLink)
  const isMultiplePlayLinks = Boolean(launcherLink && isStreamLinkEnabled)

  const onLauncherButtonClick = async () => {
    toggleLauncherOverlay()
    const link = await addPlayLinkParams({
      link: launcherLink,
      useTransferToken,
    })
    if (link) window.location.href = link
  }

  const platform = getAttensiProductPlatformForOS()
  const isMobileDevice = IS_APP || platform === "iOS" || platform === "Android"
  const isPlayable = launcherLink || isStreamLinkEnabled

  // Real Talk is currently not supported on mobile devices, but might be in the future
  if (isMobileDevice) return <NotSupported.Mobile />
  if (!isPlayable)
    return <NotSupported.Platform supportedPlatforms={supportedPlatforms} />

  return (
    <>
      {isStreamLinkEnabled && <StreamButton isPrimary={!isMultiplePlayLinks} />}

      {launcherLink && (
        <LauncherButton
          isPrimary={!isMultiplePlayLinks}
          onClick={onLauncherButtonClick}
        />
      )}

      {isMultiplePlayLinks && <HowToPlay playType={["stream", "launcher"]} />}

      {isLauncherOverlayVisible && (
        <LauncherOverlay onBack={toggleLauncherOverlay} />
      )}
    </>
  )
}

type StreamButtonProps = { isPrimary: boolean }
const StreamButton = ({ isPrimary }: StreamButtonProps) => {
  const { t } = useTranslation()
  return (
    <ProductModalMain.PlayLink to="play">
      {isPrimary
        ? t("product.PLAY_TEXT_SIM")
        : t("content.PRODUCT_WEBGL_LAUNCHER")}
    </ProductModalMain.PlayLink>
  )
}

type LauncherButtonProps = {
  isPrimary: boolean
  onClick: () => void
}
const LauncherButton = ({ isPrimary, onClick }: LauncherButtonProps) => {
  const { t } = useTranslation()

  return (
    <ProductModalMain.PlayLink
      onClick={onClick}
      linkVariant={isPrimary ? "theme" : "ghost"}
    >
      {isPrimary
        ? t("product.PLAY_TEXT_SIM")
        : t("content.PRODUCT_DESKTOP_LAUNCHER")}
    </ProductModalMain.PlayLink>
  )
}

/**
 * [TODO] REMOVE WHEN REAL TALK STREAM IS READY
 *
 * RealTalk is a product which should support streaming in an iframe.
 * We haven't had a product like that yet, so for now the option to
 * use stream link is hidden behind a query parameter so it can be tested
 * but not available to customers yet
 *
 * @deprecated
 */
const useStreamLink = (streamLink: string | null) => {
  const [enableStreamQuery] = useQueryParam("enableStream")

  const isStreamLinkEnabled =
    Boolean(streamLink) && enableStreamQuery === "true"
  return { isStreamLinkEnabled }
}
