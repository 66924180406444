/**
 * Registers a service worker and manages old registrations.
 *
 * - Unregistering all existing service workers before registering a new one.
 * - Automatically switches between a development (`dev-sw.js`) and production service worker
 *   based on the `isDevBuild` flag.
 * - Handles service worker registration and updates, ensuring the latest version is installed.
 * - Adds an event listener to track the state changes of the installing service worker.
 *
 * Notes:
 * - In development mode, uses `dev-sw.js` with the `module` type.
 * - In production mode, uses the JavaScript version of the provided service worker path.
 * - Logs messages when a new service worker is installed or when it's installed for the first time.
 * - Logs warnings if service workers are not supported and errors if registration fails.
 * - Resolves with the service worker registration and the current controller, or nothing if unsupported or an error occurs.
 */
export const registerServiceWorker = async (
  serviceWorkerPath: `${string}.ts`,
  isDevBuild: boolean
) => {
  if ("serviceWorker" in navigator) {
    try {
      const oldRegistrations = await navigator.serviceWorker.getRegistrations()
      const promises: Promise<any>[] = []
      oldRegistrations.forEach((registration) =>
        promises.push(unregisterServiceWorker(registration))
      )
      await Promise.all(promises)

      const basePath = serviceWorkerPath.split(".ts")[0]?.split("/").pop()

      const path = !isDevBuild ? `/${basePath}.js` : "/dev-sw.js?dev-sw"
      const type = !isDevBuild ? "classic" : "module"
      const registration = await navigator.serviceWorker.register(path, {
        type,
      })

      registration.update()

      // Add event listener to detect when the service worker is installed
      registration.onupdatefound = () => {
        const installingWorker = registration.installing
        if (installingWorker) {
          installingWorker.onstatechange = () => {
            if (installingWorker.state === "installed") {
              if (navigator.serviceWorker.controller) {
                // New service worker is installed and ready
                console.log("New service worker installed")
              } else {
                // No controller - this means it's the first time the service worker is installed
                console.log("Service worker installed for the first time")
              }
            }
          }
        }
      }
      return { registration, controller: navigator.serviceWorker.controller }
    } catch (error) {
      console.error("Service Worker registration failed:", error)
    }
  } else {
    console.warn("Service Worker not supported")
  }
}

export const unregisterServiceWorker = async (
  registration: ServiceWorkerRegistration
) => {
  await registration.unregister()
}
