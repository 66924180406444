import { initializeGlobalErrorPopup } from "error-popup"
import "materia-scss/src/reset.scss"
import React from "react"
import ReactDOM from "react-dom/client"
import { App } from "./App"
import { initializeActionCable } from "./initialize/initializeActionCable"
import { initializeAppLauncher } from "./initialize/initializeAppLauncher"
import { initializeAuth } from "./initialize/initializeAuth"
import { initializeBackofficeApi } from "./initialize/initializeBackofficeApi"
import { initializeI18n } from "./initialize/initializeI18n"
import { initializeLeaderboardApi } from "./initialize/initializeLeaderboardApi"
import { initializeLocalise } from "./initialize/initializeLocalise"
import { initializePlausible } from "./initialize/initializePlausible"
import { initializeSentry } from "./initialize/initializeSentry"
import { initializeSharedDevice } from "./initialize/initializeSharedDevice"
import { initializeVuplex } from "./initialize/initializeVuplex"

await initializeAuth()

initializeActionCable()
initializeAppLauncher()
initializeBackofficeApi()
initializeGlobalErrorPopup()
initializeI18n()
initializeLeaderboardApi()
initializeLocalise()
initializePlausible()
initializeSentry()
initializeVuplex()
initializeSharedDevice()

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
