export const listenToServiceWorker = (
  callback: (event: Event & { data?: any }) => void,
  eventName: string
) => {
  navigator.serviceWorker.addEventListener(eventName, callback)
}

export const listenToServiceWorkerMessage = (
  callback: (event: Event & { data: any }) => void
) => {
  listenToServiceWorker(
    callback as Parameters<typeof listenToServiceWorker>[0],
    "message"
  )
}

export const removeServiceWorkerListener = (
  eventName: string,
  callback: (event: Event) => void
) => {
  navigator.serviceWorker.removeEventListener(eventName, callback)
}
