import avatarDemoIcon from "assets/icons/avatarDemoIcon.svg"
import { mockData, registerMockData, type ResultOf } from "graphql-mock"
import { graphql } from "../../graphql"

export type Data = ResultOf<typeof query>

export const query = graphql(`
  query SharedDeviceInactivityTrackerModal {
    currentCompany {
      companyFeatureFlags
    }
    currentUser {
      avatar
      avatarTitle
      displayName
    }
  }
`)

export const mockSharedDeviceInactivityTracker = mockData<Data>({
  currentCompany: {
    companyFeatureFlags: [],
  },
  currentUser: {
    avatar: avatarDemoIcon,
    avatarTitle: "Wow such nice title",
    displayName: "Hitachi",
  },
})

registerMockData(query, mockSharedDeviceInactivityTracker())
