import s from "./Iframe.module.scss"

// The iframe that we use to embed most of our products in Portal
export const Iframe = ({
  src,
  allow,
}: {
  src?: string | undefined
  allow?: string
}) => {
  return (
    <iframe
      className={s.iframe}
      src={src}
      title="Attensi product viewer"
      allow={`autoplay; encrypted-media; ${allow}`}
      allowFullScreen
    />
  )
}
