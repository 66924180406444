import checkmarkCircleGreen from "assets/icons/checkmarkCircleGreen.svg"
import { ReactComponent as Lock } from "assets/icons_new/Lock.svg"
import { ProgressBar } from "materia"
import { useTranslation } from "react-i18next"
import { modifiers } from "ui-tools"
import { FiestaLink } from "utility-components"
import { getProductLink } from "../../../dataUtilities/productDataUtilities"
import { Badge } from "../../Badge"
import { Deadline } from "../../Deadline"
import { Stars } from "../../Stars"
import type { Data } from "../gql"
import { ProductCardShell } from "../shell"
import s from "./styles.module.scss"

export const ProductCardActivity = ({ data }: { data: Data }) => {
  const { id, title, progress, activityProgress, isLocked } = data

  if (activityProgress === null) {
    throw new Error("activityProgress must exist for activity products")
  }

  const isCompleted = activityProgress.complete
  const isCertified = progress.certified

  return (
    <li>
      <ProductCardShell>
        <FiestaLink
          className={modifiers(s, "card", {
            isCompleted,
            isCertified,
            isLocked,
          })}
          to={getProductLink(id, "activity")}
          aria-haspopup="dialog"
          replace={true}
        >
          <CertifiedOrDeadline data={data} />
          {progress.mandatory && <Mandatory />}
          <Info />
          <Progress data={data} />
          <h3 className={s.card__title}>{title}</h3>
          <div className={s.card__stars}>
            {isLocked ? (
              <Lock className={s.card__lock} />
            ) : (
              <Stars count={progress.starsCount} max={progress.starsMax} />
            )}
          </div>
        </FiestaLink>
      </ProductCardShell>
    </li>
  )
}

const CertifiedOrDeadline = ({ data }: { data: Data }) => {
  const { progress, deadlineOccurrence } = data
  const status = deadlineOccurrence?.status

  if (status === "due")
    return (
      <div className={s.deadline}>
        <Deadline variant="dueDate" size="small" />
      </div>
    )
  if (status === "overdue")
    return (
      <div className={s.deadline}>
        <Deadline variant="overdue" size="small" />
      </div>
    )
  if (progress.certified) return <Certified />
}

const Certified = () => (
  <img className={s.certified} src={checkmarkCircleGreen} alt="Completed" />
)

const Mandatory = () => {
  const { t } = useTranslation()

  return (
    <div className={s.mandatory}>
      <Badge title={t("user.MANDATORY")} size="small" />
    </div>
  )
}

const Info = () => {
  const { t } = useTranslation()

  return (
    <div className={s.card__info}>{t("product.PRODUCT_TYPES.activity")}</div>
  )
}

const Progress = ({ data }: { data: Data }) => {
  const { progress, activityProgress } = data

  if (activityProgress === null) {
    throw new Error("activityProgress must exist for activity products")
  }

  const completedIfCertified = progress.certified
    ? activityProgress.progressOf
    : activityProgress.progressCount

  return (
    <div
      className={s.card__progress}
      aria-label={`${activityProgress.progressCount} out of ${activityProgress.progressOf} completed`}
    >
      {completedIfCertified}/{activityProgress.progressOf}
      <ProgressBar
        value={completedIfCertified}
        maxValue={activityProgress.progressOf}
        color={progress.certified ? "green-30" : "blue-40"}
      />
    </div>
  )
}
