// NEW, ADJUSTED ICONS
import { ReactComponent as CircleExclamationMark } from "assets/icons_new/CircleExclamationMark.svg"
import { ReactComponent as Clock } from "assets/icons_new/Clock.svg"
import { ReactComponent as Lock } from "assets/icons_new/Lock.svg"

// OLD, DEPRECATED ICONS
import { ReactComponent as About } from "assets/icons/aboutIcon.svg"
import { ReactComponent as ActivityLeaderboard } from "assets/icons/activityLeaderboardIcon.svg"
import { ReactComponent as AddToCalendar } from "assets/icons/addToCalendar.svg"
import { ReactComponent as ArrowDown } from "assets/icons/arrowDown.svg"
import { ReactComponent as ArrowLeft } from "assets/icons/arrowLeft.svg"
import { ReactComponent as ArrowRight } from "assets/icons/arrowRight.svg"
import { ReactComponent as Attendees } from "assets/icons/attendees.svg"
import { ReactComponent as AttensiLauncher } from "assets/icons/attensiLauncherIcon.svg"
import { ReactComponent as AvatarDemo } from "assets/icons/avatarDemoIcon.svg"
import { ReactComponent as Avatar } from "assets/icons/avatarIcon.svg"
import { ReactComponent as Check } from "assets/icons/checkIcon.svg"
import { ReactComponent as Checkmark } from "assets/icons/checkmark.svg"
import { ReactComponent as CheckmarkCircleGreen } from "assets/icons/checkmarkCircleGreen.svg"
import { ReactComponent as Chevron } from "assets/icons/chevronIcon.svg"
import { ReactComponent as ChevronWhite } from "assets/icons/chevronIconWhite.svg"
import { ReactComponent as ClockOld } from "assets/icons/clockIcon.svg"
import { ReactComponent as Close } from "assets/icons/closeIcon.svg"
import { ReactComponent as CloseWhite } from "assets/icons/closeIconWhite.svg"
import { ReactComponent as Completed } from "assets/icons/completedIcon.svg"
import { ReactComponent as Crown } from "assets/icons/crown.svg"
import { ReactComponent as CrownBronse } from "assets/icons/crownIconBronse.svg"
import { ReactComponent as CrownSilver } from "assets/icons/crownIconSilver.svg"
import { ReactComponent as DiplomaLocked } from "assets/icons/diplomaLocked.svg"
import { ReactComponent as DiplomaLockedSmall } from "assets/icons/diplomaLockedSmall.svg"
import { ReactComponent as DiplomaUnlocked } from "assets/icons/diplomaUnlocked.svg"
import { ReactComponent as DiplomaUnlockedSmall } from "assets/icons/diplomaUnlockedSmall.svg"
import { ReactComponent as Duration } from "assets/icons/duration.svg"
import { ReactComponent as ExploreFilled } from "assets/icons/exploreFilledIcon.svg"
import { ReactComponent as Explore } from "assets/icons/exploreIcon.svg"
import { ReactComponent as FeaturedArrow } from "assets/icons/featuredArrowIcon.svg"
import { ReactComponent as ForYouFilled } from "assets/icons/forYouFilledIcon.svg"
import { ReactComponent as ForYou } from "assets/icons/forYouIcon.svg"
import { ReactComponent as GamifiedCursor } from "assets/icons/gamifiedCursor.svg"
import { ReactComponent as GamifiedPointer } from "assets/icons/gamifiedPointer.svg"
import { ReactComponent as GrayStar } from "assets/icons/grayStarIcon.svg"
import { ReactComponent as HelpAttensi } from "assets/icons/helpAttensiIcon.svg"
import { ReactComponent as Help } from "assets/icons/helpIcon.svg"
import { ReactComponent as Host } from "assets/icons/host.svg"
import { ReactComponent as Information } from "assets/icons/informationIcon.svg"
import { ReactComponent as JourneyDescription } from "assets/icons/journeyDescriptionIcon.svg"
import { ReactComponent as JourneyFilled } from "assets/icons/journeyFilledIcon.svg"
import { ReactComponent as Journey } from "assets/icons/journeyIcon.svg"
import { ReactComponent as KPI } from "assets/icons/kpiIcon.svg"
import { ReactComponent as Language } from "assets/icons/languageIcon.svg"
import { ReactComponent as LeaderboardColor } from "assets/icons/leaderboardColorIcon.svg"
import { ReactComponent as Leaderboard } from "assets/icons/leaderboardIcon.svg"
import { ReactComponent as LeaderboardsFilled } from "assets/icons/leaderboardsFilledIcon.svg"
import { ReactComponent as Lightning } from "assets/icons/lightningIcon.svg"
import { ReactComponent as Like } from "assets/icons/likeIcon.svg"
import { ReactComponent as LikeStarBackground } from "assets/icons/likeIconStarBackground.svg"
import { ReactComponent as Logout } from "assets/icons/logoutIcon.svg"
import { ReactComponent as Medal } from "assets/icons/medalIcon.svg"
import { ReactComponent as NewsColor } from "assets/icons/newsColorIcon.svg"
import { ReactComponent as NewsFilled } from "assets/icons/newsFilledIcon.svg"
import { ReactComponent as News } from "assets/icons/newsIcon.svg"
import { ReactComponent as Pin } from "assets/icons/pin.svg"
import { ReactComponent as PlayButton } from "assets/icons/playButton.svg"
import { ReactComponent as Play } from "assets/icons/playIcon.svg"
import { ReactComponent as ProductInProgress } from "assets/icons/productInProgress.svg"
import { ReactComponent as PushNot } from "assets/icons/pushNotIcon.svg"
import { ReactComponent as RecentActivity } from "assets/icons/recentActivityIcon.svg"
import { ReactComponent as Room } from "assets/icons/room.svg"
import { ReactComponent as Search } from "assets/icons/searchIcon.svg"
import { ReactComponent as SharedDevice } from "assets/icons/sharedDeviceIcon.svg"
import { ReactComponent as StarFilled } from "assets/icons/starFilled.svg"
import { ReactComponent as StarMagic } from "assets/icons/starMagic.svg"
import { ReactComponent as StarModalEmpty } from "assets/icons/starModalEmpty.svg"
import { ReactComponent as StarModalFilled } from "assets/icons/starModalFilled.svg"
import { ReactComponent as StarNotFilled } from "assets/icons/starNotFilled.svg"
import { ReactComponent as StarTheme } from "assets/icons/starThemeIcon.svg"
import { ReactComponent as Success } from "assets/icons/successIcon.svg"
import { ReactComponent as Ticket } from "assets/icons/ticket.svg"
import { ReactComponent as UserCompleted } from "assets/icons/userCompletedIcon.svg"
import { ReactComponent as UserMandatory } from "assets/icons/userMandatoryIcon.svg"
import { ReactComponent as WaitingList } from "assets/icons/waitingList.svg"

export const icons_new = {
  Clock,
  CircleExclamationMark,
  Lock,
}

export const icons_deprecated = {
  About,
  ActivityLeaderboard,
  AddToCalendar,
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  Attendees,
  AttensiLauncher,
  AvatarDemo,
  Avatar,
  Check,
  Checkmark,
  CheckmarkCircleGreen,
  Chevron,
  ChevronWhite,
  ClockOld,
  Close,
  CloseWhite,
  Completed,
  Crown,
  CrownBronse,
  CrownSilver,
  DiplomaLocked,
  DiplomaLockedSmall,
  DiplomaUnlocked,
  DiplomaUnlockedSmall,
  Duration,
  ExploreFilled,
  Explore,
  FeaturedArrow,
  ForYouFilled,
  ForYou,
  GrayStar,
  HelpAttensi,
  Help,
  Host,
  Information,
  JourneyDescription,
  JourneyFilled,
  Journey,
  KPI,
  Language,
  LeaderboardColor,
  Leaderboard,
  LeaderboardsFilled,
  Lightning,
  Like,
  LikeStarBackground,
  Logout,
  Medal,
  NewsColor,
  NewsFilled,
  News,
  Pin,
  PlayButton,
  Play,
  ProductInProgress,
  PushNot,
  RecentActivity,
  Room,
  Search,
  SharedDevice,
  StarNotFilled,
  StarFilled,
  StarMagic,
  StarModalEmpty,
  StarModalFilled,
  StarTheme,
  Success,
  Ticket,
  UserCompleted,
  UserMandatory,
  WaitingList,
  GamifiedPointer,
  GamifiedCursor,
}

// TODO Temporary setup to show both new and old icons in the storybook.
// When the component is finished only `icons` should remain.
export const icons = {
  ...icons_new,
  ...icons_deprecated,
}

export type IconType = keyof typeof icons
