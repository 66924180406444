import type { Product, PlayLinks } from "../graphql"
import { sendVuplexMessage } from "./sendVuplexMessage"

type Data = Pick<
  Product,
  | "id"
  | "productType"
  | "companyId"
  | "title"
  | "descriptionLong"
  | "image"
  | "openExternalBrowser"
  | "showNavigationBar"
> & {
  playLinks: Pick<PlayLinks, "appStoreLink">
}

export const sendOpenProductMessage = async (data: Data, link: string) => {
  const productType = data.productType

  sendVuplexMessage({
    type: "OPEN_PRODUCT",
    payload: {
      id: Number(data.id),
      link,

      showNavigationBar: getShowNavigationBar(data),
      downloadable: productType === "pdf",

      title: data.title,
      description: data.descriptionLong,
      companyId: Number(data.companyId),
      type: data.productType,
      image: data.image,
      googlePlayLink: data.playLinks.appStoreLink,
      appStoreLink: data.playLinks.appStoreLink,
      openExternalBrowser: data.openExternalBrowser,
    },
  })
}

const getShowNavigationBar = (data: Data) => {
  const productType = data.productType

  switch (productType) {
    case "xapi":
    case "scorm":
    case "third_party":
    case "link":
    case "html":
      return data.showNavigationBar
    default:
      return false
  }
}
