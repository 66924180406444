import closeIconWhite from "assets/icons/closeIconWhite.svg"
import { ErrorFallback, Modal } from "materia"
import { useTranslation } from "react-i18next"
import s from "./ViewerModalError.module.scss"

export const ViewerModalError = ({
  error,
  onClose,
}: {
  error: unknown
  onClose: () => void
}) => {
  const { t } = useTranslation()
  return (
    <Modal ariaLabel="Viewer load error" overflowAuto={false} onClose={onClose}>
      <div className={s.viewerError}>
        <div className={s.viewerError__closeButton}>
          <CloseButton onClick={onClose} />
        </div>
        <div className={s.viewerError__content}>
          <ErrorFallback
            error={error}
            title={t("error.GENERIC")}
            message={t("error.VIEWER_ERROR")}
          />
        </div>
      </div>
    </Modal>
  )
}

const CloseButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <button className={s.closeButton} onClick={onClick} aria-label="Close">
      <img className={s.closeButton__icon} src={closeIconWhite} alt="" />
    </button>
  )
}
