import { useErrorSentryLog } from "materia"
import { useTranslation } from "react-i18next"
import s from "./styles.module.scss"

export const IncompleteFilterError = ({
  error,
  isIncomplete,
  isMandatory,
}: {
  error: unknown
  isIncomplete: boolean | undefined
  isMandatory: boolean | undefined
}) => {
  // TODO [sentry-logging] https://jira.attensi.com/browse/WEB-18745
  // Find a better place to send Sentry logs from
  useErrorSentryLog(error)
  const { t } = useTranslation()

  const getTitle = () => {
    if (isIncomplete && isMandatory) return t("user.INCOMPLETE_AND_MANDATORY")
    if (isMandatory) return t("user.MANDATORY")
    return t("user.INCOMPLETE")
  }

  return (
    <div className={s.incompleteFilter}>
      <div className={s.incompleteFilter__header}>{getTitle()}</div>
      <div className={s.incompleteFilter__description}>
        {t("error.FILTERS_FETCH_ERROR")}
      </div>
    </div>
  )
}
