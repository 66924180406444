import confusedClouds from "assets/illustrations/confusedClouds.svg"
import { Card, useErrorSentryLog } from "materia"
import { useTranslation } from "react-i18next"
import { FiestaImage } from "utility-components"

import s from "./styles.module.scss"

export const FilteredProductsError = ({ error }: { error: unknown }) => {
  // TODO [sentry-logging] https://jira.attensi.com/browse/WEB-18745
  // Find a better place to send Sentry logs from
  useErrorSentryLog(error)
  const { t } = useTranslation()

  return (
    <Card borderRadius="24">
      <div className={s.noProducts}>
        <FiestaImage
          alt=""
          sizes="50px"
          src={confusedClouds}
          className={s.noProducts__image}
        />
        <div className={s.noProducts__details}>
          <p className={s.noProducts__title}>{t("error.GENERIC")}</p>
          <p className={s.noProducts__description}>
            {t("error.FILTERED_PRODUCTS_FETCH_ERROR")}
          </p>
        </div>
      </div>
    </Card>
  )
}
