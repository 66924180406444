import { Link } from "@tanstack/react-location"
import logoutIcon from "assets/icons//logoutIcon.svg"
import aboutIcon from "assets/icons/aboutIcon.svg"
import chevronIcon from "assets/icons/chevronIcon.svg"
import closeIcon from "assets/icons/closeIcon.svg"
import helpAttensiIcon from "assets/icons/helpAttensiIcon.svg"
import languageIcon from "assets/icons/languageIcon.svg"
import pushIcon from "assets/icons/pushNotIcon.svg"
import sharedDeviceIcon from "assets/icons/sharedDeviceIcon.svg"
import adminLogoDarkModeOff from "assets/logos/adminLogoDarkModeOff.svg"
import attensiLogoGrey from "assets/logos/attensiLogoGrey.svg"
import { auth } from "auth"
import { useGraphqlQuery } from "graphql-mock"
import { useMenu } from "hooks"
import { Dropdown, MenuList, Toggle, useWindowWidth } from "materia"
import { type ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { modifiers } from "ui-tools"
import { ErrorPopup } from "utility-components"
import { getHasAdminAccess } from "../../../dataUtilities/getHasAdminAccess"
import { getLocaleDisplayName } from "../../../dataUtilities/getLocaleDisplayName"
import { ADMIN_URL, APP_ENV } from "../../../env"
import { changeLocale } from "../../../i18n/changeLocale"
import { APP_VERSION, IS_APP } from "../../../vuplex/constants"
import { sendVuplexMessage } from "../../../vuplex/sendVuplexMessage"
import { useVuplexStore } from "../../../vuplex/vuplexStore"
import { MenuAnimation } from "../../MenuAnimation"
import {
  canUseSharedDeviceFlag,
  mustUseSharedDeviceFlag,
  useSharedDeviceStore,
} from "../../SharedDeviceInactivityTrackerModal/store"
import { CompanyPicker } from "../CompanyPicker"
import { query, type Data } from "./gql"
import s from "./styles.module.scss"

export const HeaderMenu = ({ initialIsOpen }: { initialIsOpen?: boolean }) => {
  const { i18n } = useTranslation()

  const { isOpen, toggleMenu, containerRef } = useMenu(initialIsOpen)

  const { data, error, isPending } = useGraphqlQuery(query, {
    locale: i18n.language,
  })

  if (error) return <ErrorPopup error={error} />

  return (
    <div className={modifiers(s, "headerMenu", { isOpen })} ref={containerRef}>
      <button
        className={s.headerMenu__button}
        onClick={toggleMenu}
        title="Header menu"
        aria-expanded={isOpen}
        aria-haspopup="menu"
      />
      <MenuAnimation className={s.headerMenu__content} isOpen={isOpen}>
        {isPending ? null : <Content data={data} toggleMenu={toggleMenu} />}
      </MenuAnimation>
    </div>
  )
}

const Content = ({
  data,
  toggleMenu,
}: {
  data: Data
  toggleMenu: () => void
}) => {
  return (
    <div className={s.content}>
      <ContentHeader toggleIsOpen={toggleMenu} />

      <Company data={data} />

      <YourProfile data={data} toggleMenu={toggleMenu} />

      <LanguageDropdown data={data} />

      <HelpCenter data={data} toggleMenu={toggleMenu} />

      <About data={data} />

      <PushNotifications />

      <SharedDevice data={data} />

      <Admin data={data} />

      <Logout data={data} />

      <DeleteMe data={data} />

      <div className={s.headerMenu__bottom}>
        <AppVersion />
        <AttensiLogo />
      </div>
    </div>
  )
}

const Company = ({ data }: { data: Data }) => {
  const { currentCompany } = data

  if (IS_APP) return

  return (
    <CompanyItem
      companyName={currentCompany.name ?? ""}
      icon={currentCompany.logoUrl}
      menu={<CompanyPicker />}
    />
  )
}

const YourProfile = ({
  data,
  toggleMenu,
}: {
  data: Data
  toggleMenu: () => void
}) => {
  const { t } = useTranslation()

  const { currentCompany, currentUser } = data
  const { portalFeatureFlags } = currentCompany

  if (portalFeatureFlags.includes("disable_user_profile")) return

  return (
    <BigItem
      title={t("settings.YOUR_PROFILE")}
      description={currentUser.position ?? ""}
      icon={currentUser.avatar}
      imageDescription={currentUser.avatarTitle}
      isBigIcon={true}
      onClick={toggleMenu}
      to="/me"
    />
  )
}

const HelpCenter = ({
  data,
  toggleMenu,
}: {
  data: Data
  toggleMenu: () => void
}) => {
  const { t } = useTranslation()

  const { currentCompany } = data
  const { companyFeatureFlags } = currentCompany

  if (companyFeatureFlags.includes("disable_help_links")) return

  return (
    <BigItem
      title={t("settings.HELP_ATTENSI")}
      icon={helpAttensiIcon}
      description={t("settings.HELP_ATTENSI_DESCRIPTION")}
      onClick={toggleMenu}
      href="https://help.attensi.com/hc/en-us"
    />
  )
}

const PushNotifications = () => {
  const { t } = useTranslation()
  const { pushNotificationsEnabled } = useVuplexStore()

  if (pushNotificationsEnabled === undefined) return

  const togglePushNotifications = (pushNotificationsEnable: boolean) => {
    sendVuplexMessage({
      type: "REQUEST_PUSH_NOTIFICATIONS_ENABLED",
      payload: { pushNotificationsEnable },
    })
  }

  return (
    <ToggleItem
      title={t("settings.PUSH_NOTIFICATIONS")}
      icon={pushIcon}
      checked={pushNotificationsEnabled}
      onChange={togglePushNotifications}
    />
  )
}

const SharedDevice = ({ data }: { data: Data }) => {
  const { t } = useTranslation()

  const { currentCompany } = data
  const { companyFeatureFlags } = currentCompany

  // isSharedDeviceEnabled is true if either of the flags are present in the BE,
  // making it possible to set the device as shared.
  const isSharedDeviceEnabled = companyFeatureFlags.some((flag) =>
    [canUseSharedDeviceFlag, mustUseSharedDeviceFlag].includes(flag)
  )

  // isSharedDevice is the current device state, describing if the device is shared or not.
  const { isSharedDevice, setIsSharedDevice, mustUseSharedDevice } =
    useSharedDeviceStore()

  const toggleSharedDevice = (newValue: boolean) => {
    setIsSharedDevice(newValue)
    sendVuplexMessage({
      type: "SET_IS_SHARED_DEVICE",
      payload: { isSharedDevice: newValue },
    })
  }

  if (!isSharedDeviceEnabled) {
    return
  }

  return (
    <ToggleItem
      icon={sharedDeviceIcon}
      title={t("settings.SHARED_DEVICE")}
      checked={isSharedDevice}
      onChange={toggleSharedDevice}
      disabled={mustUseSharedDevice}
    />
  )
}

const Admin = ({ data }: { data: Data }) => {
  const { t } = useTranslation()

  if (!getHasAdminAccess(data.currentUser.roles)) return

  return (
    <BigItem
      onClick={openAdmin}
      title={t("settings.ADMIN")}
      icon={adminLogoDarkModeOff}
    />
  )
}

const Logout = ({ data }: { data: Data }) => {
  const { t } = useTranslation()
  const { currentCompany } = data
  const { companyFeatureFlags } = currentCompany

  const enableSLORedirect = companyFeatureFlags.includes(
    "enable_single_log_out"
  )

  const onLogout = () => {
    sendVuplexMessage({ type: "LOGOUT" })
    if (enableSLORedirect) auth.logoutAndSLORedirect()
    else auth.logoutAndLogin()
  }

  return (
    <BigItem
      title={t("settings.LOGOUT")}
      onClick={onLogout}
      icon={logoutIcon}
    />
  )
}

const DeleteMe = ({ data }: { data: Data }) => {
  const { t } = useTranslation()
  const { currentCompany } = data

  const showDeleteMe =
    currentCompany.selfRegistration && APP_ENV === "production"

  if (!showDeleteMe) return

  const onClick = async () => {
    const link = await auth.addAccessParams("https://delete-me.attensi.com")
    window.location.href = link
  }

  return <SmallItem onClick={onClick}>{t("settings.DELETE_MY_DATA")}</SmallItem>
}

const openAdmin = async () => {
  const link = await auth.addAccessParams(ADMIN_URL)

  if (IS_APP) {
    sendVuplexMessage({
      type: "OPEN_LINK",
      payload: { link, openExternalBrowser: true },
    })
  } else {
    window.location.href = link
  }
}

const ContentHeader = ({ toggleIsOpen }: { toggleIsOpen: () => void }) => {
  const { t } = useTranslation()
  const { isSmallWindow } = useWindowWidth()
  if (!isSmallWindow) return null

  return (
    <div className={s.contentHeader}>
      <div className={s.contentHeader__content}>
        <div className={s.contentHeader__title}>{t("MENU")}</div>
        <button
          className={s.contentHeader__button}
          onClick={toggleIsOpen}
          title="Close menu"
        >
          <img src={closeIcon} className={s.contentHeader__closeIcon} alt="" />
        </button>
      </div>
    </div>
  )
}

const CompanyItem = ({
  companyName,
  icon,
  menu,
  ...props
}: {
  companyName: string
  icon: string
  menu?: ReactNode
  href?: string
  to?: string
  onClick?: () => void
}) => {
  const { isOpen, toggleMenu, containerRef } = useMenu()

  return (
    <div className={modifiers(s, "companyItem", { isOpen })} ref={containerRef}>
      <LinkOrButton
        className={s.companyItem__linkOrButton}
        onClick={toggleMenu}
        aria-expanded={isOpen}
        {...props}
      >
        <div className={s.companyItem__left}>
          <img className={s.companyItem__icon} src={icon} alt="" />
          <div className={s.companyItem__companyName}>{companyName}</div>
        </div>
        <img className={s.companyItem__chevron} src={chevronIcon} alt="" />
      </LinkOrButton>

      <MenuAnimation className={s.companyItem__menu} isOpen={isOpen}>
        {menu}
      </MenuAnimation>
    </div>
  )
}

const BigItem = ({
  title,
  icon,
  imageDescription = "",
  description,
  onClick,
  isBigIcon,
  ...props
}: {
  title: string
  icon: string
  imageDescription?: string
  description?: string
  href?: string
  to?: string
  isBigIcon?: boolean
  isInteractive?: boolean
  onClick?: () => void
}) => {
  return (
    <div className={modifiers(s, "bigItem", { isBigIcon })}>
      <LinkOrButton
        className={s.bigItem__linkOrButton}
        onClick={onClick}
        openInNewTab={true}
        {...props}
      >
        <img className={s.bigItem__icon} src={icon} alt={imageDescription} />
        <div className={s.bigItem__left}>
          <div className={s.bigItem__title}>{title}</div>
          {description && (
            <p className={s.bigItem__description}>{description}</p>
          )}
        </div>
      </LinkOrButton>
    </div>
  )
}

const ToggleItem = ({
  title,
  icon,
  checked,
  onChange,
  disabled,
}: {
  title: string
  icon: string
  onChange: (checked: boolean) => void
  checked: boolean
  disabled?: boolean
}) => (
  <div className={s.bigItem}>
    <label className={s.bigItem__linkOrButton}>
      <img className={s.bigItem__icon} src={icon} alt="" />
      <Toggle
        label={title}
        checked={checked}
        onChange={(event) => onChange(event.currentTarget.checked)}
        disabled={disabled}
      />
    </label>
  </div>
)

const SmallItem = ({
  title,
  children,
  ...props
}: {
  title?: ReactNode
  children?: ReactNode
  to?: string
  href?: string
  onClick?: () => void
}) => (
  <LinkOrButton className={s.smallItem} {...props}>
    {title}
    {children}
  </LinkOrButton>
)

const AppVersion = () => {
  const { t } = useTranslation()

  if (!APP_VERSION) return

  return (
    <div className={s.appVersion}>
      {t("settings.APP_VERSION")} {APP_VERSION}
    </div>
  )
}

const LanguageDropdown = ({ data }: { data: Data }) => {
  const { t, i18n } = useTranslation()

  const { currentCompany } = data
  const { portalSupportedLocales } = currentCompany

  const onLanguageChange = (localeCode: string) => {
    changeLocale(localeCode)
    sendVuplexMessage({ type: "SET_LANGUAGE", payload: { localeCode } })
  }

  return (
    <Dropdown value={i18n.language} onChange={onLanguageChange}>
      <Dropdown.CustomButton>
        <BigItem
          title={t("settings.LANGUAGE")}
          icon={languageIcon}
          description={getLocaleDisplayName(i18n.language)}
          isInteractive={false}
        />
      </Dropdown.CustomButton>
      <Dropdown.Options>
        {portalSupportedLocales.map((locale) => (
          <Dropdown.Option key={locale} value={locale}>
            {getLocaleDisplayName(locale)}
          </Dropdown.Option>
        ))}
      </Dropdown.Options>
    </Dropdown>
  )
}

const About = ({ data }: { data: Data }) => {
  const { t } = useTranslation()

  return (
    <MenuList>
      <MenuList.CustomButton>
        <BigItem
          title={t("settings.ABOUT")}
          icon={aboutIcon}
          isInteractive={false}
        />
      </MenuList.CustomButton>
      <MenuList.Items>
        <AboutList data={data} />
      </MenuList.Items>
    </MenuList>
  )
}

const AboutList = ({ data }: { data: Data }) => {
  const { t } = useTranslation()

  const { currentCompany } = data
  const { privacyPolicy } = currentCompany

  return (
    <>
      <MenuList.ExternalLinkItem href="https://legal.attensi.com/min-spec.html">
        {t("settings.MINIMUM_TECHNICAL_SPECIFICATION")}
      </MenuList.ExternalLinkItem>

      {privacyPolicy && (
        <MenuList.ExternalLinkItem href={privacyPolicy}>
          {t("settings.PRIVACY_POLICY")}
        </MenuList.ExternalLinkItem>
      )}

      <MenuList.ExternalLinkItem href="https://legal.attensi.com/eula.html">
        {t("settings.TERMS_OF_USE")}
      </MenuList.ExternalLinkItem>

      <MenuList.ExternalLinkItem href="https://legal.attensi.com/licenses.html">
        {t("settings.LICENSES")}
      </MenuList.ExternalLinkItem>

      <MenuList.ExternalLinkItem href="https://legal.attensi.com/cookie-policy.html">
        {t("settings.COOKIE_POLICY")}
      </MenuList.ExternalLinkItem>
    </>
  )
}

const AttensiLogo = () => {
  return (
    <img className={s.attensiLogo} src={attensiLogoGrey} alt="Attensi logo" />
  )
}

const LinkOrButton = ({
  to,
  href,
  openInNewTab,
  isInteractive = true,
  ...props
}: {
  children: ReactNode
  className?: string
  to?: string
  href?: string
  openInNewTab?: boolean
  isInteractive?: boolean
  onClick?: () => void
}) => {
  if (!isInteractive) {
    return <div {...props} />
  }

  if (to) {
    return <Link to={to} {...props} />
  }

  if (href) {
    const target = openInNewTab ? "_blank" : undefined
    return (
      <a href={href} target={target} {...props}>
        {props.children}
      </a>
    )
  }

  return <button {...props} />
}
