import { useToggle } from "hooks"
import { useTranslation } from "react-i18next"
import { getStaticLauncherLink } from "../../../../tools/getStaticLauncherLink"
import { ProductModalHelp } from "../ProductModalHelp"

type PlayType = "stream" | "webgl" | "launcher"

type HowToPlayProps = { playType: PlayType[] }
export const HowToPlay = ({ playType }: HowToPlayProps) => {
  const [showHelpModal, toggleHelpModal] = useToggle()
  return (
    <>
      <ProductModalHelp.HelpButton onClick={toggleHelpModal} />
      {showHelpModal && (
        <ModalHowToPlay playType={playType} onToggle={toggleHelpModal} />
      )}
    </>
  )
}

type ModalHowToPlayProps = { playType: PlayType[]; onToggle: () => void }
export const ModalHowToPlay = ({ playType, onToggle }: ModalHowToPlayProps) => {
  return (
    <ProductModalHelp onClose={onToggle} ariaLabel="How to play product">
      <ProductModalHelp.CloseButton onClick={onToggle} />

      {playType.includes("webgl") && <InfoWebGL />}
      {playType.includes("stream") && <InfoStream />}
      {playType.includes("launcher") && <InfoDesktop />}
    </ProductModalHelp>
  )
}

const InfoWebGL = () => {
  const { t } = useTranslation()
  return (
    <ProductModalHelp.Items title={t("content.PRODUCT_WEBGL_LAUNCHER")}>
      <ProductModalHelp.Item>
        {t("content.PRODUCT_WEBGL_LAUNCHER_DESC.0")}
      </ProductModalHelp.Item>
      <ProductModalHelp.Item>
        {t("content.PRODUCT_WEBGL_LAUNCHER_DESC.1")}
      </ProductModalHelp.Item>
      <ProductModalHelp.Item>
        {t("content.PRODUCT_WEBGL_LAUNCHER_DESC.2")}
      </ProductModalHelp.Item>
    </ProductModalHelp.Items>
  )
}

const InfoStream = () => {
  const { t } = useTranslation()
  return (
    <ProductModalHelp.Items title={t("content.PRODUCT_STREAM_LAUNCHER")}>
      <ProductModalHelp.Item>
        {t("content.PRODUCT_STREAM_LAUNCHER_DESC.0")}
      </ProductModalHelp.Item>
      <ProductModalHelp.Item>
        {t("content.PRODUCT_STREAM_LAUNCHER_DESC.1")}
      </ProductModalHelp.Item>
      <ProductModalHelp.Item>
        {t("content.PRODUCT_STREAM_LAUNCHER_DESC.2")}
      </ProductModalHelp.Item>
      <ProductModalHelp.Item>
        {t("content.PRODUCT_STREAM_LAUNCHER_DESC.3")}
      </ProductModalHelp.Item>
    </ProductModalHelp.Items>
  )
}

const InfoDesktop = () => {
  const { t } = useTranslation()
  return (
    <ProductModalHelp.Items title={t("content.PRODUCT_DESKTOP_LAUNCHER")}>
      <ProductModalHelp.Item>
        {t("content.PRODUCT_DESKTOP_LAUNCHER_DESC.0")}
        <ProductModalHelp.Link
          href={getStaticLauncherLink()}
          text={t("content.PRODUCT_DOWNLOAD_LAUNCHER")}
        />
      </ProductModalHelp.Item>
      <ProductModalHelp.Item>
        {t("content.PRODUCT_DESKTOP_LAUNCHER_DESC.1")}
      </ProductModalHelp.Item>
      <ProductModalHelp.Item>
        {t("content.PRODUCT_DESKTOP_LAUNCHER_DESC.2")}
      </ProductModalHelp.Item>
      <ProductModalHelp.Item>
        {t("content.PRODUCT_DESKTOP_LAUNCHER_DESC.3")}
      </ProductModalHelp.Item>
    </ProductModalHelp.Items>
  )
}
