import { useGraphqlQuery } from "graphql-mock"
import { useWindowWidth } from "materia"
import { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { optimizeImage, useOptimizeContext } from "ui-tools"
import { ErrorPopup, FiestaLink } from "utility-components"
import { Avatar } from "../../Avatar"
import { useCurrentCompanyContext } from "../CurrentCompanyProvider"
import { useCurrentUserContext } from "../CurrentUserProvider"
import { HeaderMenu } from "../HeaderMenu"
import { NavigationBar } from "../Navigation"
import { query } from "./gql"
import s from "./styles.module.scss"

export const Header = () => {
  const { isBigWindow } = useWindowWidth()
  const { t } = useTranslation()

  const { data, error, isPending } = useGraphqlQuery(query, {})
  const { currentUser, currentUserError, isCurrentUserPending } =
    useCurrentUserContext()
  const { currentCompany, currentCompanyError, isCurrentCompanyPending } =
    useCurrentCompanyContext()

  const isLoading = isPending || isCurrentUserPending || isCurrentCompanyPending
  const isError = error || currentUserError || currentCompanyError

  if (isLoading) return <HeaderLoading />
  if (isError) return <ErrorPopup error={error} />

  return (
    <header className={s.header}>
      <a href="#main" className={s.header__skipToMainContent}>
        {t("actions.SKIP_TO_MAIN_CONTENT")}
      </a>
      <div className={s.header__appLauncherButton}>
        <AppLauncherButton />
      </div>
      <div className={s.header__container}>
        <Logo src={currentCompany.logoUrl} />

        {!isBigWindow && (
          <FiestaLink className={s.header__avatarWrapper} to="/me">
            <Avatar
              className={s.header__avatar}
              alt={currentUser.avatarTitle}
              image={currentUser.avatar}
              size="medium"
            />
            <div className={s.header__userDisplayName}>
              {currentUser.displayName}
            </div>
          </FiestaLink>
        )}

        {isBigWindow && <NavigationBar data={data.navigationCompany} />}

        <HeaderMenu />
      </div>
    </header>
  )
}

const HeaderLoading = () => {
  return <div className={s.headerSkeleton} />
}

const Logo = ({ src }: { src: string }) => {
  const context = useOptimizeContext()

  const optimizeLogo = (targetHeight: number) => {
    const { app, optimizeUrl } = context || {}
    if (!app || !optimizeUrl) return src

    const height = app.heights.find((height) => height >= targetHeight)
    return optimizeImage({ app, optimizeUrl, src, height })
  }

  return (
    <FiestaLink to="/">
      <img
        className={s.header__logo}
        src={optimizeLogo(50)}
        alt="Company logo"
      />
    </FiestaLink>
  )
}

const AppLauncherButton = () => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current) {
      createButton(ref.current)
    }
  }, [])

  return <div ref={ref} />
}

const createButton = async (element: HTMLDivElement) => {
  const { createAppLauncherButton } = await import("@attensi/app-launcher")

  createAppLauncherButton(element)
}
