import { create } from "zustand"

type VuplexStore = {
  miniModalIsOpen: boolean
  productId: number | undefined
  pushNotificationsEnabled: boolean | undefined
}

export const useVuplexStore = create<VuplexStore>(() => ({
  miniModalIsOpen: false,
  productId: undefined,
  pushNotificationsEnabled: undefined,
}))
