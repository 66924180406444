import { Loader } from "materia"
import type { ReactNode } from "react"
import { QueryBoundary } from "utility-components"
import { custom } from "../../bonzai/bonzai"
import { DiplomaWidget } from "./DiplomaWidget"
import { EventWidget } from "./EventWidget"
import { KpiSalesWidgetLoader } from "./KpiSalesWidget/KpiSalesWidgetLoader"
import { LeaderboardWidgetLoader } from "./LeaderboardWidget/LeaderboardWidgetLoader"
import { NewsWidget } from "./NewsWidget"
import { RecentActivityWidget } from "./RecentActivityWidget"
import { SubHeaders } from "./SubHeaders"
import { UserWidget } from "./UserWidget"
import { WidgetLayout } from "./WidgetLayout"

type WidgetLayoutLoaderProps = {
  children: ReactNode
  showUserInfoHeader?: boolean
}
export const WidgetLayoutLoader = ({
  children,
  showUserInfoHeader,
}: WidgetLayoutLoaderProps) => (
  <>
    <SubHeaders showUserInfoHeader={showUserInfoHeader} />
    <WidgetLayout widgets={<WidgetsLoader />}>
      <QueryBoundary fallback={<Loader />}>{children}</QueryBoundary>
    </WidgetLayout>
  </>
)

const WidgetsLoader = () => {
  // TODO [error-handling-effort] https://jira.attensi.com/browse/WEB-18497
  const { data: flags } = custom.getPortalConfigurations.useQuery([])

  if (!flags) return <WidgetLayout.WidgetsSkeleton />

  return (
    <>
      <UserWidget />
      <DiplomaWidget />
      <EventWidget />
      {flags.enable_portal_news && <NewsWidget />}
      {!flags.disable_leaderboard && <LeaderboardWidgetLoader />}
      <RecentActivityWidget />
      {flags.enable_kpi_sales_tiles && <KpiSalesWidgetLoader />}
    </>
  )
}
