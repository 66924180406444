import arrow from "assets/icons/arrowRight.svg"
import diplomaLockedIcon from "assets/icons/diplomaLocked.svg"
import diplomaUnlockedIcon from "assets/icons/diplomaUnlocked.svg"
import { useGraphqlQuery } from "graphql-mock"
import { Card } from "materia"
import { useTranslation } from "react-i18next"
import { modifiers } from "ui-tools"
import { FiestaImage, FiestaLink } from "utility-components"
import { Deadline } from "../../Deadline"
import { DiplomaWidgetError } from "./DiplomaWidgetError"
import { DiplomaWidgetSkeleton } from "./DiplomaWidgetSkeleton"
import { query, type Data } from "./gql"
import s from "./styles.module.scss"

export const DiplomaWidget = () => {
  const { data, error, isPending } = useGraphqlQuery(query, {
    page: 1,
    limit: 3,
  })

  if (isPending) return <DiplomaWidget.Skeleton />
  if (error) return <DiplomaWidget.Error error={error} />

  return <DiplomaWidget.Load diplomas={data.products.nodes} />
}

const Load = ({ diplomas }: { diplomas: Data["products"]["nodes"] }) => {
  const { t } = useTranslation()

  if (!diplomas.length) return null

  return (
    <Card borderRadius="24">
      <div className={s.widget}>
        <div className={s.widget__header}>
          <div className={s.widget__text}>{t("diploma.WIDGET_TITLE")}</div>
          <FiestaLink
            to="/me"
            className={s.widget__link}
            ariaLabel="Open my profile"
          >
            <FiestaImage
              className={s.widget__linkIcon}
              src={arrow}
              alt="Arrow"
              sizes="60px"
            />
          </FiestaLink>
        </div>
        <div className={s.widget__diplomaList}>
          {diplomas.map((diploma) => (
            <Diploma
              key={diploma.id}
              title={diploma.diplomaConfiguration?.title}
              isUnlocked={diploma.progress.certified}
              deadlineStatus={diploma.deadlineOccurrence?.status}
            />
          ))}
        </div>
      </div>
    </Card>
  )
}

type DiplomaProps = {
  deadlineStatus?: string
  title?: string | null
  isUnlocked: Boolean
}
const Diploma = ({ deadlineStatus, title, isUnlocked }: DiplomaProps) => {
  const getDeadlineStatus = () => {
    switch (deadlineStatus) {
      case "due":
        return <Deadline variant="dueDate" size="small" />
      case "overdue":
        return <Deadline variant="overdue" size="small" />
    }
  }

  return (
    <div className={s.diploma}>
      <div className={s.diploma__info}>
        <div className={modifiers(s, "diploma__icon", { isUnlocked })}>
          {isUnlocked ? (
            <img src={diplomaUnlockedIcon} alt="Unlocked" />
          ) : (
            <img src={diplomaLockedIcon} alt="Locked" />
          )}
        </div>
        <div className={modifiers(s, "diploma__name", { isUnlocked })}>
          {title}
        </div>
      </div>
      <div className={s.diploma__deadline}>{getDeadlineStatus()}</div>
    </div>
  )
}

DiplomaWidget.Skeleton = DiplomaWidgetSkeleton
DiplomaWidget.Error = DiplomaWidgetError
DiplomaWidget.Load = Load
