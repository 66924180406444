import closeIcon from "assets/icons/closeIcon.svg"
import informationIcon from "assets/icons/informationIcon.svg"
import { Modal } from "materia"
import type { PropsWithChildren, ReactNode } from "react"
import s from "./styles.module.scss"

type ProductModalHelpProps = {
  ariaLabel: string
  children: ReactNode
  onClose: () => void
}
export const ProductModalHelp = ({
  ariaLabel,
  onClose,
  children,
}: ProductModalHelpProps) => (
  <Modal onClose={onClose} ariaLabel={ariaLabel}>
    <div className={s.productModalHelp}>{children}</div>
  </Modal>
)

ProductModalHelp.Title = ({ title }: { title: string }) => (
  <div className={s.title}>{title}</div>
)

ProductModalHelp.Description = ({ description }: { description: string }) => (
  <div className={s.description}>{description}</div>
)

ProductModalHelp.Items = ({
  title,
  children,
}: {
  title: string
  children: ReactNode
}) => (
  <div className={s.items}>
    <ProductModalHelp.Title title={title} />
    <ul className={s.items__description}>{children}</ul>
  </div>
)

ProductModalHelp.Item = ({ children }: PropsWithChildren) => (
  <li className={s.item}>{children}</li>
)

ProductModalHelp.Link = ({ href, text }: { href: string; text: string }) => (
  <a href={href} className={s.link}>
    {text}
  </a>
)

ProductModalHelp.HelpButton = ({ onClick }: { onClick: () => void }) => (
  <button className={s.helpButton} onClick={onClick}>
    <img className={s.helpButton__icon} src={informationIcon} alt="Help" />
  </button>
)

ProductModalHelp.CloseButton = ({ onClick }: { onClick: () => void }) => (
  <button className={s.closeButton} onClick={onClick} aria-label="Close">
    <img className={s.closeButton__icon} src={closeIcon} alt="" />
  </button>
)
