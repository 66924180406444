import { useErrorSentryLog } from "materia"
import { useTranslation } from "react-i18next"
import s from "./styles.module.scss"

export const ExploreFiltersError = ({ error }: { error: unknown }) => {
  // TODO [sentry-logging] https://jira.attensi.com/browse/WEB-18745
  // Find a better place to send Sentry logs from
  useErrorSentryLog(error)
  const { t } = useTranslation()

  return (
    <div className={s.filters}>
      <p className={s.filters__error}>{t("error.FILTERS_FETCH_ERROR")}</p>
    </div>
  )
}
