import { useGraphqlQuery } from "graphql-mock"
import { useTranslation } from "react-i18next"
import { modifiers } from "ui-tools"
import { FiestaLink } from "utility-components"
import type { DeadlineStatus } from "../../../graphql"
import { IncompleteFilterError } from "./error"
import { query } from "./gql"
import { IncompleteFilterLoading } from "./loading"
import s from "./styles.module.scss"

export const IncompleteFilter = ({
  deadlineStatus,
  isIncomplete,
  isMandatory,
}: {
  isIncomplete: boolean | undefined
  isMandatory: boolean | undefined
  deadlineStatus: DeadlineStatus | undefined
}) => {
  const { t } = useTranslation()
  const { data, error, isPending } = useGraphqlQuery(query, {})

  if (isPending) return <IncompleteFilterLoading />
  if (error)
    return (
      <IncompleteFilterError
        error={error}
        isIncomplete={isIncomplete}
        isMandatory={isMandatory}
      />
    )

  const { userProgressSummary, deadlineProgressSummary } = data
  const { productCompleteCount, productCompleteMax } = userProgressSummary
  const incompleteCount = productCompleteMax - productCompleteCount
  const { dueCount, overdueCount } = deadlineProgressSummary
  const showDeadlineFilters = dueCount > 0 || overdueCount > 0

  const getTitle = () => {
    if (isIncomplete && isMandatory) return t("user.INCOMPLETE_AND_MANDATORY")
    if (isMandatory) return t("user.MANDATORY")
    return t("user.INCOMPLETE")
  }

  return (
    <div className={s.incompleteFilter}>
      <div className={s.incompleteFilter__header}>{getTitle()}</div>
      <div className={s.incompleteFilter__description}>
        {t("user.INCOMPLETE_PRODUCTS", { count: incompleteCount })}
      </div>
      {showDeadlineFilters && (
        <div className={s.incompleteFilter__filters}>
          <Filter
            variant="due"
            isActive={deadlineStatus === "due"}
            count={dueCount}
          />
          <Filter
            variant="overdue"
            isActive={deadlineStatus === "overdue"}
            count={overdueCount}
          />
        </div>
      )}
    </div>
  )
}

const Filter = ({
  isActive,
  variant,
  count,
}: {
  isActive: boolean
  count: number
  variant: "overdue" | "due"
}) => {
  const { t } = useTranslation()

  return (
    <FiestaLink
      className={modifiers(s, "filter", variant, { isActive })}
      search={(search) => ({
        ...search,
        deadline_status: isActive ? undefined : variant,
      })}
    >
      {variant === "due" ? t("user.DUE_DATE") : t("user.OVERDUE")}
      <div className={s.filter__count}>{count}</div>
    </FiestaLink>
  )
}
