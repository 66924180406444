import type { onMessageToSw } from "service-worker"

export const INCOMING_EVENTS = {
  TIMEOUT_START: "TIMEOUT_START",
  TIMEOUT_INITIALIZE: "TIMEOUT_INITIALIZE",
  TIMEOUT_STOP: "TIMEOUT_STOP",
  TIMEOUT_TICK_CONFIRM: "TIMEOUT_TICK_CONFIRM",
  TIMEOUT_RESUME: "TIMEOUT_RESUME",
} as const

export const OUTGOING_EVENTS = {
  TIMEOUT_CANCEL: "TIMEOUT_CANCEL",
  TIMEOUT_END: "TIMEOUT_END",
  TIMEOUT_TICK: "TIMEOUT_TICK",
  DO_LOGOUT: "DO_LOGOUT",
  DO_PROMPT: "DO_PROMPT",
  ERROR: "ERROR",
} as const

export type OutgoingEvent = keyof typeof OUTGOING_EVENTS
export type IncomingEvent = keyof typeof INCOMING_EVENTS

export type MessageToServiceWorker = { type: IncomingEvent }

export type TickEvent = {
  type: typeof OUTGOING_EVENTS.TIMEOUT_TICK
  logoutIn: number
}

export type ErrorEvent = {
  type: typeof OUTGOING_EVENTS.ERROR
  context: string
  message: Error["message"] | string
  stack: Error["stack"] | undefined
  timestamp: string
}

export type MessageFromServiceWorker =
  | {
      type: Omit<OutgoingEvent, TickEvent["type"] & ErrorEvent["type"]>
    }
  | TickEvent
  | ErrorEvent

export type ServiceWorkerMessageEvent = Event & {
  data?: MessageFromServiceWorker
}

export type MessageEvent = Parameters<
  Parameters<typeof onMessageToSw>[0]
>[0] & {
  data?: MessageToServiceWorker
}

export type TimerReply = { timeBeforeReply: number; replyType: OutgoingEvent }

export type TimerPayload = {
  replies: TimerReply[]
  runTime: number
}

export type StartTimerEvent = MessageEvent & {
  data: {
    payload: TimerPayload
  }
}
