import portalBackground from "assets/demo/portalBackground.svg"
import attensiLogo from "assets/logos/attensiLogoBlue.svg"
import { ForceStateContext, useGraphqlQuery } from "graphql-mock"
import { createContext, useContext, type ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { query, type Data } from "./gql"

type CurrentCompanyContextType = {
  currentCompany: Data["currentCompany"]
  currentCompanyError: Error | null
  isCurrentCompanyPending: boolean
}

const CurrentCompanyContext = createContext<CurrentCompanyContextType | null>(
  null
)

const initialCompany: Data["currentCompany"] = {
  backgroundUrl: "",
  companyFeatureFlags: [],
  companyButtonColor: "",
  companyStarColor: "",
  defaultLocale: "",
  enabledApps: [],
  id: "0",
  logoUrl: "",
  name: "",
  nameKey: "",
  portalBackgroundUrl: "",
  portalFeatureFlags: [],
  portalLandingPage: "explore",
  portalSupportedLocales: [],
  privacyPolicy: "",
  selfRegistration: false,
  userDisplayNameFormat: "username",
}

// Note: The context is only intended to provide the "current company" data.
// Add only new properties if they exist on the current company. If you wish to provide other global data, consider a dedicated Context.
export const useCurrentCompanyContext = () => {
  const context = useContext(CurrentCompanyContext)

  if (context === null) {
    console.error(
      "Company context is not present. You're probably trying to access it outside the boundary of the CompanyProvider"
    )
    throw Error("Company context is not present")
  }

  return context
}

export const CurrentCompanyProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const { i18n } = useTranslation()

  const { data, error, isPending } = useGraphqlQuery(query, {
    locale: i18n.language,
  })

  return (
    <CurrentCompanyContext.Provider
      value={{
        currentCompany: data?.currentCompany || initialCompany,
        currentCompanyError: error,
        isCurrentCompanyPending: isPending,
      }}
    >
      {children}
    </CurrentCompanyContext.Provider>
  )
}

// Mocked version of CurrentCompanyProvider, to be used exclusively as a Storybook decorator.
export const MockedCurrentCompanyProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  /**
   * TODO [api-decoupling] ForceStateContext used here is a temporary solution to handle
   * the fact that some components are coupled with API, and some are actively refactored
   * not to be. It allows us to unify the two approaches, so we can properly mock entire views.
   *
   * This should be removed once all components are decoupled from the API!
   */
  const { forceState } = useContext(ForceStateContext)
  const isCurrentCompanyPending = forceState === "loading"

  const mockedCurrentCompany: Data["currentCompany"] = {
    backgroundUrl: portalBackground,
    companyFeatureFlags: [],
    companyButtonColor: "",
    companyStarColor: "",
    defaultLocale: "",
    enabledApps: ["portal"],
    id: "0",
    logoUrl: attensiLogo,
    name: "Attensi",
    nameKey: "attensi",
    portalBackgroundUrl: "",
    portalFeatureFlags: ["enable_portal_background"],
    portalLandingPage: "journey",
    portalSupportedLocales: ["en-US"],
    privacyPolicy: "",
    selfRegistration: false,
    userDisplayNameFormat: "username",
  }

  return (
    <CurrentCompanyContext.Provider
      value={{
        currentCompany: mockedCurrentCompany,
        isCurrentCompanyPending,
        currentCompanyError: null,
      }}
    >
      {children}
    </CurrentCompanyContext.Provider>
  )
}
