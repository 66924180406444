import { useAsyncData } from "hooks"
import { useEffect } from "react"
import { addPlayLinkParams } from "../../tools/addPlayLinkParams"
import { sendVuplexMessage } from "../../vuplex/sendVuplexMessage"

export const useAutoRotation = () => {
  useEffect(() => {
    sendVuplexMessage({
      type: "SET_SCREEN_ORIENTATION",
      payload: { screenOrientation: "AUTO" },
    })

    return () => {
      sendVuplexMessage({
        type: "SET_SCREEN_ORIENTATION",
        payload: { screenOrientation: "PORTRAIT" },
      })
    }
  }, [])
}

export const useWebglLink = (
  productId: string,
  webglLink: string | null,
  useTransferToken: boolean
) => {
  return useAsyncData(
    () =>
      addPlayLinkParams({
        link: webglLink,
        useTransferToken: useTransferToken,
      }),
    [productId]
  )
}

export const useStreamLink = (
  productId: string,
  streamLink: string | null,
  useTransferToken: boolean
) => {
  const { data, isPending, error } = useAsyncData(
    () =>
      addPlayLinkParams({
        link: streamLink,
        useTransferToken,
      }),
    [productId]
  )

  const isNoLink = !error && !isPending && data === undefined

  return {
    data,
    isPending,
    error: isNoLink ? new Error("No streamLink for product") : error,
  }
}
