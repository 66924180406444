import { invalidateAllQueries } from "bonzai"
import { useSharedDeviceStore } from "../components/SharedDeviceInactivityTrackerModal/store"
import { getCompanyCached } from "../graphql/getCompanyCached"
import { changeLocale } from "../i18n/changeLocale"
import { sendVuplexMessage } from "../vuplex/sendVuplexMessage"
import { subscribeToVuplex } from "../vuplex/subscribeToVuplex"
import { useVuplexStore } from "../vuplex/vuplexStore"

export const initializeVuplex = () => {
  subscribeToVuplex("SET_MINI_MODAL_PRODUCT_ID", (message) => {
    useVuplexStore.setState({
      productId: message.payload,
      miniModalIsOpen: true,
    })
  })

  subscribeToVuplex("CLOSE_PRODUCT_MODALS", () => {
    useVuplexStore.setState({
      miniModalIsOpen: false,
    })
  })

  subscribeToVuplex("REFRESH_PRODUCT_SCORE", () => {
    // Might not be needed anymore with the live updates with action cable
    // TODO: Test without it
    invalidateAllQueries()
  })

  subscribeToVuplex("SET_PUSH_NOTIFICATIONS_ENABLED", (message) => {
    useVuplexStore.setState({
      pushNotificationsEnabled: message.payload,
    })
  })

  subscribeToVuplex("SET_IS_SHARED_DEVICE", (message) => {
    useSharedDeviceStore.setState({
      isSharedDevice: message.payload,
    })
  })

  subscribeToVuplex("SET_LANGUAGE", async (event) => {
    const currentCompany = await getCompanyCached()
    const { portalSupportedLocales } = currentCompany

    const locale = event.payload.localeCode
    const isValid = portalSupportedLocales.includes(locale)
    if (isValid) changeLocale(locale)
  })

  sendVuplexMessage({ type: "READY" })
}
