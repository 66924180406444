import {
  SHARED_DEVICE_STORAGE_KEY,
  useSharedDeviceStore,
} from "../components/SharedDeviceInactivityTrackerModal/store"

export const initializeSharedDevice = () => {
  const isDeviceShared = localStorage.getItem(SHARED_DEVICE_STORAGE_KEY)
  if (isDeviceShared) {
    useSharedDeviceStore.setState({
      isSharedDevice: JSON.parse(isDeviceShared),
    })
  }
}
