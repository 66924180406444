import * as Sentry from "@sentry/react"
import { useCallback, useEffect } from "react"
import {
  listenToServiceWorkerMessage,
  removeServiceWorkerListener,
} from "service-worker"
import {
  INCOMING_EVENTS,
  OUTGOING_EVENTS,
  type ServiceWorkerMessageEvent,
  type TickEvent,
} from "../../serviceWorker/helpers/backgroundTimeoutEventTypes"
import { useServiceWorker } from "../../tools/useServiceWorker"
import { useSharedDeviceStore } from "./store"

type RefineServiceWorkerEvent<DataType> = Omit<
  ServiceWorkerMessageEvent,
  "data"
> & {
  data: DataType
}

/**
 * A React hook for managing background inactivity timeouts and responding to events from a service worker.
 *
 * - Monitors user inactivity and triggers events such as prompting the user or logging them out.
 * - Handles service worker events like heartbeat ticks, errors, and lifecycle messages.
 * - Integrates with the application to manage visibility changes and ensure the timer is appropriately resumed.
 *
 * @example
 * const { setInactivityTimeout } = useBackgroundTimeout({
 *   onPrompt: () => openPromptModal(),
 *   onLogout: () => logOutUser(),
 *   onTick: (timeElapsed) => updateTimerDisplay(timeElapsed),
 * });
 *
 * useEffect(() => {
 *   // Start the inactivity timeout
 *   setInactivityTimeout();
 * }, []);
 */
const useBackgroundTimeout = ({
  onPrompt,
  onLogout,
  onTick,
}: {
  onPrompt: () => void
  onLogout: () => void
  onTick: (timeElapsed: number) => void
}) => {
  const { controller, postMessage } = useServiceWorker(
    "/serviceWorker/service-worker.ts"
  )

  const isSharedDevice = useSharedDeviceStore((state) => state.isSharedDevice)
  const isPlayingAttensiProduct = useSharedDeviceStore(
    (state) => state.isPlayingAttensiProduct
  )

  /**
   * Start the inactivity timeout. Used for callback in event listeners in the application.
   */
  const setInactivityTimeout = useCallback(() => {
    postMessage({
      type: INCOMING_EVENTS.TIMEOUT_START,
    })
  }, [postMessage])

  /**
   * Callback to handle a heartbeat tick from the service worker.
   * It should not keep the timer running if the device is not shared or if the user is playing an Attensi product.
   * Otherwise it should respond with a heartbeat TIMEOUT_TICK_CONFIRM.
   * Also do callback onTick passed to the hook for the application to handle the tick.
   * @param event
   * @returns
   */
  const tick = (event: RefineServiceWorkerEvent<TickEvent>) => {
    if (!isSharedDevice || isPlayingAttensiProduct) {
      return
    }
    postMessage({ type: INCOMING_EVENTS.TIMEOUT_TICK_CONFIRM })
    onTick(event.data.logoutIn)
  }

  /**
   * Callback to report error to sentry
   * @param event
   */
  const error = (event: RefineServiceWorkerEvent<ErrorEvent>) => {
    const { type: _, ...context } = event.data
    Sentry.withScope((scope) => {
      scope.setContext("serviceWorkerError", context)
      Sentry.captureException(new Error(event.data.message))
    })
  }

  /**
   * Handle events from the service worker
   *  - DO_PROMPT: Happens halfway through the logout time and should pop the inactivity modal
   *  - DO_LOGOUT: Happens when the user has been inactive for too long and should log the user out
   *  - TIMEOUT_TICK: Happens every second and should update the timer in the inactivity modal. The client should also respond with a heartbeat TIMEOUT_TICK_CONFIRM
   *    to keep the timer running.
   *  - ERROR: Happens when an error occurs in the service worker. The client is responsible for properly reporting the error.
   * @param event
   * @returns
   */
  const messageCallback = (event: ServiceWorkerMessageEvent) => {
    const type = event.data?.type

    switch (type) {
      case OUTGOING_EVENTS.DO_PROMPT:
        onPrompt()
        break
      case OUTGOING_EVENTS.DO_LOGOUT:
        onLogout()
        break
      case OUTGOING_EVENTS.TIMEOUT_TICK:
        tick(event as RefineServiceWorkerEvent<TickEvent>)
        break
      case OUTGOING_EVENTS.ERROR:
        error(event as RefineServiceWorkerEvent<ErrorEvent>)
        break
      default:
        return
    }
  }

  /**
   * Resume the timer when the tab is visible
   * (i.e. it has been a background tab or the device has been idle/locked and is brought back to focus)
   */
  const resumeTimer = () => {
    if (document.visibilityState === "visible") {
      postMessage({ type: INCOMING_EVENTS.TIMEOUT_RESUME })
    }
  }

  useEffect(() => {
    if (controller) {
      postMessage({ type: INCOMING_EVENTS.TIMEOUT_INITIALIZE })
      setInactivityTimeout()
      listenToServiceWorkerMessage(messageCallback)
      window.addEventListener("visibilitychange", resumeTimer)
    }
    return () => {
      removeServiceWorkerListener("message", messageCallback)
      window.removeEventListener("visibilitychange", resumeTimer)
    }
  }, [controller])

  return { setInactivityTimeout }
}

export { useBackgroundTimeout }
