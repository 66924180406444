import { type ResultOf } from "graphql-mock"
import { graphql } from "../../../graphql"

export type Data = ResultOf<typeof query>
export type Product = Data["products"]["nodes"][number]

export const query = graphql(`
  query DiplomaWidget($limit: Int!, $page: Int) {
    products(input: { variant: with_certificate }, limit: $limit, page: $page) {
      nodes {
        id
        progress {
          certified
        }
        diplomaConfiguration {
          title
        }
        deadlineOccurrence {
          status
        }
      }
    }
  }
`)
