import { useTranslation } from "react-i18next"
import { ATTENSI_PRODUCT_PLATFORM_FOR_OS } from "../../../../constants"
import s from "./styles.module.scss"

type Props = { supportedPlatforms: string[] }
export const NotSupported = ({ supportedPlatforms }: Props) => {
  const { t } = useTranslation()
  const title = `${t(
    "product.NOT_SUPPORTED_GENERIC"
  )} ${ATTENSI_PRODUCT_PLATFORM_FOR_OS}`
  const supportedBy = t("product.SUPPORTED_PLATFORMS")
  const platforms = supportedPlatforms.join(" / ")

  return (
    <div className={s.notSupported}>
      <div className={s.notSupported__title}>{title}</div>
      <div className={s.notSupported__supportedBy}>
        {supportedBy}
        <span className={s.notSupported__platforms}>{platforms}</span>
      </div>
    </div>
  )
}

const NotSupportedMobile = () => {
  const { t } = useTranslation()

  return (
    <div className={s.notSupported}>
      <div className={s.notSupported__title}>
        {t("product.NOT_SUPPORTED_MOBILE")}
      </div>
    </div>
  )
}

// Alias for NotSupported to make it consistent with the Mobile counterpart
// as they are used in the same contexts
NotSupported.Platform = NotSupported
NotSupported.Mobile = NotSupportedMobile
