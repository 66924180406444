import { useToggle } from "hooks"
import { useTranslation } from "react-i18next"
import { ProductModalHelp } from "../ProductModalHelp"

export const HowToDownload = () => {
  const [showHelpModal, toggleHelpModal] = useToggle()
  return (
    <>
      <ProductModalHelp.HelpButton onClick={toggleHelpModal} />
      {showHelpModal && <ModalHowToDownload onToggle={toggleHelpModal} />}
    </>
  )
}

export const ModalHowToDownload = ({ onToggle }: { onToggle: () => void }) => {
  const { t } = useTranslation()
  return (
    <ProductModalHelp onClose={onToggle} ariaLabel="Play button not working">
      <ProductModalHelp.CloseButton onClick={onToggle} />
      <ProductModalHelp.Title title={t("helpModal.TITLE")} />
      <ProductModalHelp.Description description={t("helpModal.DESCRIPTION")} />
    </ProductModalHelp>
  )
}
