import { useCallback, useEffect, useState } from "react"
import { listenToServiceWorker, registerServiceWorker } from "service-worker"
import { IS_DEV_BUILD } from "../env"
import type { MessageToServiceWorker } from "../serviceWorker/helpers/backgroundTimeoutEventTypes"

/**
 * A React hook for managing a service worker in the application.
 *
 * - Automatically installs and initializes the service worker on the client.
 * - Tracks the current service worker registration and controller.
 * - Provides a function to send messages to the service worker.
 *
 * @example
 * const { controller, postMessage } = useServiceWorker('/sw.js.ts');
 *
 * // Post a message to the service worker
 * postMessage({ type: 'PING' });
 *
 * // Check if a service worker is active
 * if (controller) {
 *   console.log('Service Worker is active:', controller);
 * }
 *
 * Note:
 * In general, service workers need https to be able to register.
 * vite-plugin-pwa manages to register in localhost for chromium and epiphany.
 * On firefox you either need to set up a local server with https or debug from a hosted build.
 */
const useServiceWorker = (workerPath: `${string}.ts`) => {
  const [_, setServiceWorkerRegistration] =
    useState<ServiceWorkerRegistration | null>(null)

  const [controller, setController] = useState<ServiceWorker | null>(null)

  /**
   * Initialize/install the service worker on the client
   */
  const initializeServiceWorker = useCallback(async () => {
    const registry = await registerServiceWorker(workerPath, IS_DEV_BUILD)

    if (registry) {
      const { registration, controller: initController } = registry

      if (registration) {
        setServiceWorkerRegistration(registration)
      }

      if (initController) {
        setController(initController)
      }
    }
  }, [workerPath])

  useEffect(() => {
    initializeServiceWorker()

    // We need to update the current controller whenever it changes
    // otherwise we will be using an outdated controller and communication will fail
    listenToServiceWorker(() => {
      setController(navigator.serviceWorker.controller)
    }, "controllerchange")
  }, [initializeServiceWorker])

  /**
   * Post a message to the service worker
   */
  const postMessage = useCallback(
    (message: MessageToServiceWorker) => {
      controller?.postMessage(message)
    },
    [controller]
  )

  return { controller, postMessage }
}

export { useServiceWorker }
