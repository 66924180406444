import { ReactComponent as StarNotFilled } from "assets/icons/starNotFilled.svg"
import { ReactComponent as StarThemeIcon } from "assets/icons/starThemeIcon.svg"
import { times } from "lodash-es"
import { modifiers } from "ui-tools"
import s from "./styles.module.scss"

type StarSize = "extraSmall" | "small" | "medium" | "large"

export const Stars = ({
  count,
  max,
  size = "medium",
}: {
  count: number
  max: number
  size?: StarSize
}) => {
  return (
    <div
      className={modifiers(s, "star", size)}
      aria-label={`${count} of ${max} stars`}
      style={{ color: "var(--company-star-color)" }}
    >
      {times(max, (index) =>
        index < count ? <StarThemeIcon /> : <StarNotFilled />
      )}
    </div>
  )
}
