import { create } from "zustand"

export const SHARED_DEVICE_STORAGE_KEY = "is-shared-device"

export const mustUseSharedDeviceFlag = "must_use_shared_device"
export const canUseSharedDeviceFlag = "can_use_shared_device"

type Store = {
  isSharedDevice: boolean

  mustUseSharedDevice: boolean

  isPlayingAttensiProduct: boolean

  setIsSharedDevice: (isSharedDevice: boolean) => void

  setMustUseSharedDevice: () => void

  setIsPlayingAttensiProduct: (isPlayingAttensiProduct: boolean) => void
}

export const useSharedDeviceStore = create<Store>((set, get) => ({
  isSharedDevice: false,

  mustUseSharedDevice: false,

  isPlayingAttensiProduct: false,

  setIsSharedDevice: (isSharedDevice: boolean) => {
    set({ isSharedDevice })
    localStorage.setItem(
      SHARED_DEVICE_STORAGE_KEY,
      JSON.stringify(get().isSharedDevice)
    )
  },

  setMustUseSharedDevice: () => {
    set({ mustUseSharedDevice: true })
    set({ isSharedDevice: true })
  },

  setIsPlayingAttensiProduct(isPlayingAttensiProduct) {
    set({ isPlayingAttensiProduct })
  },
}))
